<template>
  <div class="module">
    <div v-if="title" class="wrapper wrapper-mobilepadding">
      <h1 v-if="title" class="gallery__title">{{ title }}</h1>
      <p v-if="description" class="gallery__description">{{ description }}</p>
    </div>
    
    <div class="gallery wrapper wrapper-mobilepadding">
      <div v-if="items.length" class="gallery__items">
        <div
          v-for="(item, i) in items" 
          :key="item.title + i"
          class="gallery__item"
          @click.prevent="followUrl(item)"
        >
          <div class="gallery__item__wrapper flex align-start space-between column gap">
            <div class="flex space-between column gap">
              <div class="spacer-half">
                <Tags :tags="item.tags"/>
              </div>

              <h3 class="gallery__item__title">{{ item.title }}</h3>
            </div>

            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>

      <div v-else class="gallery__items">
        <div
          v-for="item in ghostItems" 
          :key="item.id"
          class="gallery__item__ghost"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from './general/Tags.vue';

export default {
  name: 'ContentGallery',
  components: {
    Tags
  },
  props: {
    title: {
      type: String,
      default: 'Gallery'
    },
    description: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ghostItems: [
        { id: 1 },
        { id: 2 },
        { id: 3 }
      ]
    }
  },
  methods: {
    followUrl(item) {
      if (item.id) {
        this.$router.push({
          path: '/blog/' + item.slug
        });
      } else {
        this.$router.push({
          path: item.url
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.gallery {
  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: spacing(3);
    padding: spacing(5) 0;
  }

  &__item {
    @include text;
    padding: spacing(2);
    border-radius: $borderRadius;
    box-shadow: var(--shadow-elevation-low);
    background-color: transparent;
    border: 2px solid var(--darker-color-1);
    cursor: pointer;

    transition: 
      border-color .2s $transition,
      background-color .3s $transition;

    &:hover {
      border-color: transparent;
      background-color: var(--darker-color-1);
    }
  }

  &__item__title {
    line-height: 1.1em;
  }

  &__item__wrapper {
    height: 100%;
  }
}

</style>