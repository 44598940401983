<template>
  <div class="color-picker">
    <IconButton 
      v-if="userColor"
      icon="close" 
      @click.native="useDefaultColor()"
    />
    <IconButton 
      icon="drop" 
      @click.native="changeColor(randColor())"
    />
  </div>
</template>

<script>
import convert from 'color-convert';

import IconButton from '@/components/general/IconButton.vue';

export default {
  name: 'ColorPicker',
  components: {
    IconButton
  },
  data() {
    return {
      defaultColor: '#856adc',
      userColor: false
    }
  },
  methods: {
    changeColor: function (color) {
      localStorage.setItem('userColor', color);
      this.userColor = color;

      const converted = convert.hex.hsl(color.slice(1));

      document.documentElement.style.setProperty('--dark-color-h', converted[0]);
      document.documentElement.style.setProperty('--dark-color-s', '62%');
      document.documentElement.style.setProperty('--dark-color-l', '64%');
    },
    useUserColor: function () {
      const savedUserColor = localStorage.getItem('userColor');

      if (savedUserColor) {
        this.changeColor(savedUserColor);
      }
    },
    useDefaultColor: function () {
      localStorage.removeItem('userColor');
      this.userColor = false;

      document.documentElement.style.setProperty('--dark-color-h', '254');
      document.documentElement.style.setProperty('--dark-color-s', '62%');
      document.documentElement.style.setProperty('--dark-color-l', '64%');
    },
    randColor: () => Math.floor(Math.random()*16777215).toString(16),
  },
  created() {
    this.useUserColor();
  }
}
</script>

<style lang="scss" scoped>
.color-picker {
  position: fixed;
  bottom: calc(var(--spacing) * 2);
  right: calc(var(--spacing) * 2);
  display: flex;
  flex-direction: row;
  gap: var(--spacing);

  @include breakpoint('medium') {
    position: relative;
    bottom: 0;
    right: 0;
  }
}
</style>