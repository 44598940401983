<template>
  <svg :class="{ 'inline' : inline }" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 685.6 600.1" style="enable-background:new 0 0 685.6 600.1;" xml:space="preserve">
    <g>
      <g class="logo-gut" :class="{ 'dark' : dark, 'light' : light }">
        <path d="M198.6,335.2v166.3c0,30.8-8.1,54.9-24.4,72.4s-40.5,26.2-72.5,26.2c-32.1,0-56.3-8.7-72.6-26.2
          c-16.3-17.4-24.4-41.6-24.4-72.4V335.2h50.8v166.3c0,15.9,3.7,28.4,11.2,37.3c7.4,9,19.1,13.5,35,13.5s27.6-4.5,35-13.5
          s11.2-21.4,11.2-37.3V335.2H198.6z"/>
        <path d="M300.6,335.2l45.5,105.3h9.2l45.4-105.3h55l-55.4,125.1v6.6l55.4,125.9h-55l-45.4-106h-9.2l-45.4,106h-55l55.4-125.9v-6.6
          l-55.4-125.1H300.6z"/>
        <path d="M499.7,335.2h170.1v48.4H550.6v55.8h114.6v48.4H550.6v56.6h123.9v48.4H499.7V335.2z"/>
      </g>
      <g class="logo-uxe" :class="{ 'dark' : dark, 'light' : light }">
        <path d="M147.8,138.6h50.8v136.2h-46.2v-30h-6.9c-3.1,10-9,18.4-17.7,25.2c-8.7,6.8-21.8,10.2-39.3,10.2
          c-12.3,0-23.9-2.3-34.6-6.9c-10.8-4.6-20.1-11.5-28.1-20.6s-14.2-20.4-18.9-33.9C2.3,205.3,0,189.6,0,171.7v-63.1
          c0-17.4,2.5-32.9,7.5-46.4s11.9-24.8,20.8-34.1c8.9-9.2,19.4-16.2,31.8-21C72.4,2.4,86,0,100.9,0c15.4,0,29.1,2.5,41.2,7.5
          s22.3,11.9,30.8,20.8c8.5,8.9,14.9,19.2,19.2,31.2c4.4,11.9,6.5,24.7,6.5,38.3v4.6h-50.8v-4.6c0-16.2-4.2-28.4-12.5-36.8
          c-8.3-8.3-20.1-12.5-35.2-12.5c-14.4,0-26.2,4.8-35.4,14.4c-9.2,9.6-13.9,26.1-13.9,49.5v57c0,41.6,16.9,62.4,50.8,62.4
          c15.1,0,26.6-4,34.4-12.1s11.7-18.9,11.7-32.5L147.8,138.6z"/>
        <path d="M447.7,6.9v176.3c0,30.8-8.1,54.9-24.4,72.4c-16.3,17.5-40.5,26.2-72.5,26.2c-32.1,0-56.3-8.7-72.6-26.2
          c-16.3-17.4-24.4-41.6-24.4-72.4V6.9h50.8v176.3c0,15.9,3.7,28.4,11.2,37.3c7.4,9,19.1,13.5,35,13.5c15.9,0,27.6-4.5,35-13.5
          s11.2-21.4,11.2-37.3V6.9H447.7z"/>
        <path d="M491.6,6.9h194v48.5H614v220.9h-50.8V55.4h-71.6L491.6,6.9L491.6,6.9z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  vertical-align: middle;
}

.logo-gut,
.logo-uxe {
  fill: var(--light-color);

  &.dark {
    fill: var(--darker-color-1);
  }

  &.light {
    fill: var(--light-color);
  }
}

.inline {
  display: inline-block;
  line-height: 1em;
  width: 1em;
  vertical-align: middle;
}
</style>