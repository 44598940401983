import Vue from 'vue';
import Vuex from 'vuex';

import get from '@/utils/get.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loaded: {
      header: false,
      footer: false,
      apps: false
    },
    homepage: {},
    legalPages: [],
    blog: [],
    apps: [
      {
        name: 'wp-migrate-sql',
        title: 'Migrate Wordpress Database',
        description: 'Generate a WP Database Migration SQL Snippet with ease.',
        tags: ['SQL', 'Wordpress', 'Helper'],
        url: '/app/wp-migrate-sql',
        path: '../views/apps/WPMigrateSQL.vue',
      }
    ]
  },
  mutations: {
    mutateHomepage: (state, payload) => {
      state.homepage = payload;
    },
    mutateLegalPages: (state, payload) => {
      state.legalPages.push(...payload);
    },
    mutateBlogpost: (state, payload) => {
      state.blog.push(...payload);
    },
    mutateBlog: (state, payload) => {
      state.blog = payload;
    }
  },
  getters: {
    homepage: (state) => state.homepage,

    legalPage: (state) => (slug) => {
      return state.legalPages.find(page => page.slug === slug);
    },

    blog: (state) => state.blog,
    blogpost: (state) => (slug) => {
      return state.blog.find(post => post.slug === slug);
    },

    applications: (state) => state.apps,
    application: (state) => (slug) => {
      return state.apps.find(app => app.name === slug);
    },
  },
  actions: {
    loadHomepage: async ({ state, commit }) => {
      if (!Object.keys(state.homepage).length) {
        commit('mutateHomepage', await get.homepage())
      }
    },
    loadLegalPage: async ({ state, commit }, slug) => {
      const pageExists = state.legalPages.find(page => page.slug === slug);

      if (!pageExists) {
        commit('mutateLegalPages', await get.legalPage(slug));
      }
    },
    loadBlogpost: async ({ state, commit }, slug) => {
      const postExists = state.blog.find(post => post.slug === slug);

      if (!postExists) {
        commit('mutateBlogpost', await get.item('blog', slug));
      }
    },
    loadBlog: async ({ commit }) => commit('mutateBlog', await get.collection('blog')),
  }
})
