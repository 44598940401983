import instance from '@/utils/instance.js'

const get = {
  homepage: async () => {
    const filter = {
      limit: -1
    }

    return await instance.items('homepage').readByQuery(filter)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error)
      });
  },
  legalPage: async (slug) => {
    const filter = {
      filter: {
        slug: {
          _eq: slug
        }
      }
    }

    return await instance.items('legal_pages').readByQuery(filter)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error)
      });
  },
  collection: async (collection) => {
    const filter = { 
      limit: -1
    }

    return await instance.items(collection).readByQuery(filter)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error)
      });
  },
  item: async (item, slug) => {
    const filter = {
      filter: {
        slug: {
          _eq: slug
        }
      }
    }

    return await instance.items(item).readByQuery(filter)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error)
      });
  }
}

export default get;