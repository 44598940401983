import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    alias: '/apps',
    component: Home
  },
  {
    path: '/app/:slug',
    name: 'WP Migrate SQL',
    component: () => import(/* webpackChunkName: "appspage" */ '../views/AppsPage.vue')
  },
  {
    path: '/blog/:slug',
    name: 'Blogpost',
    component: () => import(/* webpackChunkName: "blogpost" */ '../views/Blogpost.vue')
  },
  {
    path: '/legal/:slug',
    name: 'Legal Page',
    component: () => import(/* webpackChunkName: "legalpage" */ '../views/LegalPage.vue')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "pagenotfound" */ '../views/PageNotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: ((to, from, savedPosition) => {
    if(to.hash) {
      return { x: 0, y: document.querySelector(to.hash).offsetTop - 50 };
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  })
});


export default router;
