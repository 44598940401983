<template>
  <div class="tag-container">
    <template v-if="tags.length">
      <span v-for="tag in tags" :key="tag" class="tag">{{ tag }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ghostTags: [
        { id: 1 },
        { id: 2 },
        { id: 3 }
      ]
    };
  },
}
</script>

<style lang="scss" scoped>
  .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .tag {
    @include caption;
    @include font-mono;
    font-size: .8rem;
    position: relative;
    padding: spacing(.5) spacing(2);
    border-radius: 20px;
    border: 1px solid var(--light-color);
    color: var(--light-color);
    line-height: 20px;
  }
</style>