<template>
  <div>
    <div class="wrapper wrapper-mobilepadding page flex column center">
      <h1 class="rendered" v-html="homepage.greeting"/>

      <div class="flex row start wrap gap mt">
        <a 
          v-for="(link, i) in homepage.links"
          :key="i"
          :href="link.link"
          :class="'social-link ' + link.icon"
          :title="link.tooltip"
          :target="link.target"
          v-html="link.name"
        />
      </div>
    </div>

    <div class="wrapper wrapper-nopadding">
      <ContentGallery title="Work" :items="[...blog]"/>
      <ContentGallery title="Apps" :items="[...applications]"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentGallery from '../components/ContentGallery.vue';

export default {
  name: 'Home',
  components: {
    ContentGallery
  },
  computed: {
    ...mapGetters([
      'homepage',
      'blog',
      'applications'
    ])
  },
  beforeCreate() {
    this.$store.dispatch('loadHomepage');
    this.$store.dispatch('loadBlog');
  }
}
</script>

<style lang="scss" scoped>
  .get-in-contact {
    white-space: nowrap;
  }

  h1.rendered p {
    color: var(--darker-color-1);
  }

  .social-link {
    @include font-mono;
    padding: spacing(1) spacing(2) spacing(1) spacing(1.5);

    &:before {
      content: '';
      display: inline-block;
      background-color: var(--light-color);
      height: 1em;
      aspect-ratio: 1;
      margin-right: 5px;
      transform: translateY(2px);
    }
  }

  .linkedin:before {
    mask: url("data:image/svg+xml,%3Csvg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3ELinkedIn%3C/title%3E%3Cpath d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z'/%3E%3C/svg%3E");
  }

  .github:before {
    mask: url("data:image/svg+xml,%3Csvg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EGitHub%3C/title%3E%3Cpath d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12'/%3E%3C/svg%3E");
  }

  .mail:before {
    mask: url("data:image/svg+xml,%3Csvg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EMinutemailer%3C/title%3E%3Cpath d='M17.187 19.181L24 4.755 0 12.386l9.196 1.963.043 4.896 2.759-2.617-2.147-2.076 7.336 4.63z'/%3E%3C/svg%3E");
  }

  .threema:before {
    mask: url("data:image/svg+xml,%3Csvg role='img' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EThreema%3C/title%3E%3Cpath d='M11.998 20.486a1.757 1.757 0 1 1 0 3.514 1.757 1.757 0 0 1 0-3.514zm-6.335 0a1.757 1.757 0 1 1 0 3.514 1.757 1.757 0 0 1 0-3.514zm12.671 0a1.757 1.757 0 1 1 0 3.514 1.757 1.757 0 0 1 0-3.514zM12 0c5.7 0 10.322 4.066 10.322 9.082 0 5.016-4.622 9.083-10.322 9.083a11.45 11.45 0 0 1-4.523-.917l-5.171 1.293 1.105-4.42c-1.094-1.442-1.733-3.175-1.733-5.039C1.678 4.066 6.3 0 12 0zm-.001 4.235A2.926 2.926 0 0 0 9.072 7.16v1.17h-.115a.47.47 0 0 0-.47.47v4.126c0 .26.21.471.47.471h6.086c.26 0 .47-.21.47-.47V8.798a.47.47 0 0 0-.47-.47h-.115v-1.17a2.927 2.927 0 0 0-2.93-2.924zm0 1.17c.972 0 1.758.786 1.758 1.754v1.17h-3.514v-1.17c0-.968.786-1.754 1.756-1.754z'/%3E%3C/svg%3E");
  }
</style>
