<template>
  <div id="app">
    <div id="navigationContainer">
      <div class="wrapper">
        <div class="flex row space-between align-center gap">
          <div id="logo" @click.prevent="$router.push({path: '/'})">
              <Logo dark/>
          </div>

          <nav id="nav" class="flex row gap space-between align-center">
            <ColorPicker :colors="['#7f5af0', '#e16162', '#437287', '#474363']" />

            <a href="mailto:moritz@gutuxe.de" class="primary-cta">
              Get in Contact
            </a>
          </nav>
        </div>
      </div>
    </div>

    <main>
      <transition name="view-change" mode="out-in">
        <router-view />
      </transition>
    </main>

    <footer>
      <div class="wrapper wrapper-mobilepadding">
        <div class="content">
          <span class="copy">
            <a class="menu-links" href="https://moritzgut.de" target="_blank">{{ new Date().getFullYear() }} &copy; Moritz Gut</a>
          </span>
          <span class="legal">
            <Menu :items="legalMenu"/>
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Logo from './components/general/Logo.vue';
import Menu from './components/general/Menu.vue';
import ColorPicker from './components/general/ColorPicker.vue';

export default {
  name: 'App',
  components: {
    Logo,
    Menu,
    ColorPicker
  },
  data() {
    return {
      mainMenu: [
        {
          text: 'About',
          url: '/apps'
        }
      ],
      legalMenu: [
        {
          text: 'Impressum',
          url: '/legal/impressum'
        },
        {
          text: 'Privacy',
          url: '/legal/privacy'
        }
      ]
    }
  }
}
</script>


<style lang="scss">
#navigationContainer {
  position: sticky;
  top: 0;
  background-color: var(--dark-color);
  z-index: 900;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 $outerSpacing;

    @include breakpoint('large') {
      padding: 0;
    }

    > div {
      width: 100%;
    }
  }

  #logo {
    width: 50px;
    color: var(--light-color);
    cursor: pointer;
    padding: 20px 0;

    a {
      color: var(--light-color);
      text-decoration: none;
    }
  }

  #nav {
    .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        @include subline;
        color: var(--light-color);
        line-height: spacing(6);
        padding: 0 spacing(2);
        
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

footer {
  position: relative;
  margin-bottom: 50px;

  .wrapper {
    .content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      gap: spacing(1);
      padding-bottom: spacing(2);

      @include breakpoint('small') {
        flex-direction: row;
        gap: spacing(2);
      }
    }
  }

  .legal .menu, 
  .copy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: spacing(1);

    @include breakpoint('small') {
      flex-direction: row;
      gap: spacing(2);
    }

    a {
      line-height: spacing(6);
    }
  }
}

.view-change-enter-active, .view-change-leave-active {
  transform: translateY(0px);
  opacity: 1;
  transition: 
    opacity .1s ease-in-out,
    transform .1s ease-in-out;
}
.view-change-enter, .view-change-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
